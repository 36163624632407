.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ############## COMPONENTS ############## */ 

.inputField{
  width: 100%;
  border: 0.5px solid rgb(167, 167, 167);
  padding: 16px;
  font-size: 16px;
  height: 18px;
  position: relative;
  border-radius: 8px;
}


.inputField_login{
  width: 100%;
  border: 0.5px solid rgb(167, 167, 167);
  padding: 16px;
  font-size: 16px;
  height: 51px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.loginSection{
  background: #f5f7fa;
  display: row;
  justify-content: center;
  align-items: center;
  text-align: center;  
  padding: 16px;
  width: 80%;
  padding-top:32px;
  padding-bottom: 32px;
  border-radius: 16px;
  margin-bottom: 16px;

}



.fieldTitleText {
  width: 100%; 

}



/* color buttons */ 


/* Grid */ 

.mainDiv { 
  display: grid; 
  grid-template-columns: 0.2fr 1fr;
  justify-content: center;
  height: 100vh;
}

.subDiv {
  background: white;

}

.rightMain { 
  padding: 24px;
  flex: 1; 
  overflow: auto;


}


.sectionDivider{ 
  margin-top: 48px;
  border-bottom:0.1px solid grey;
}

/* Tee Section */ 


.divBorder{ 
  display: flex;              /* Enables flexbox */
  flex-direction: column;     /* Stacks child divs vertically */
  justify-content: center;     /* Centers content horizontally */
  align-items: center; 


}

.positionSection {
  display: flex;              /* Enables flexbox */
  flex-direction: column;     /* Stacks child divs vertically */
  justify-content: center;     /* Centers content horizontally */
  align-items: center;        /* Centers content vertically */
  height: 100px;
  width: 100%;
}

.buttonsContainer {
  display: flex;              /* Enables flexbox for buttons */
  justify-content: center;    /* Centers buttons within the container */
  width: 80%;
  gap: 32px;
}

.uploadButSection {
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 32px;

  

}
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    gap: 10px;                      /* Space between grid items */
    width: 100%;
    justify-content: center;
    padding-bottom: 36px;
    margin-bottom: 24px;
}

.grid-item {
    color: white;               /* White text color */
    display: flex;   
    justify-content: center;    /* Horizontally center */
    align-items: center;        /* Vertically center */
    font-size: 24px;    
    width: 100%;      
}

/* DESIGN NAME INPUT*/ 
.top-container {
  display: grid; 
  grid-template-columns: 1fr 1fr;
  background: #f5f7fa;
  border-radius: 16px;
  padding: 16px;
  padding-top:0px;
  margin-bottom: 24px;
}


.top-subContainer{ 
  display: flex; 
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
    

}

.top-subContainer>*:first-child{
  align-self: left;
  text-align: left;

}


/* UPLOAD TEES */ 

.uploadButtonDiv { 
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 60%;             /* 50% of the screen's width */          /* Optional: adjust as needed for vertical sizing */
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
  position: relative;      /* Allows for absolute centering */
  left: 50%;               /* Pushes the div left 50% of the viewport width */
  transform: translate(-50%,0%);
}


/* SELECTED COLOURS BUTTON */ 

.button {
  background-color: black; /* Default grey background */
  border: none;           /* No stroke */
  color: white;
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 8px;

}
.button:hover {
  background-color: green; /* Change color on mouseover */
}

/*********************************/ 

/* BUTTON - POS */ 

.divPosButtons { 
  padding-top:10px;
  padding-bottom: 10px;
  display:flex; 
  flex-direction: row;


}


/* Align Buttons */ 


.buttonRows {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px ;
  text-align: center ;
}



.buttonPos {
  position: relative; /* No quotes needed for CSS values */
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  height: 51px;
  text-align: center; /* Ensures the text stays centered */
  line-height: normal; /* Resets any inherited line-height that might push the text down */
}

.buttonPos:hover {
  border: 1px solid black;
  border-radius: 6px;     /* Darker green on hover */
}


.alignBClicked{
  background-color: black;
  color: white;
  border-radius: 6px;

}


/********/ 


.loginDiv {
  margin-top: 5%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.loginInput{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}

/* BUTTON CLASS */  

.primaryButton{

  width: 100%; 
  margin-top: 12px;
  margin-bottom: 12px; 
  border-radius: 8px;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px; 
  
  background-color: black;
  color: white;
  font-size: 16px;
  border: 1px solid black;
}

.primaryButton:hover{ 
  color: #c9c9c9
}



.coreDiv {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 60%;             /* 50% of the screen's width */          /* Optional: adjust as needed for vertical sizing */
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
  position: absolute;      /* Allows for absolute centering */
  left: 50%;               /* Pushes the div left 50% of the viewport width */
  transform: translate(-50%,0%);
  padding: 20px;
  
}

/*Text*/ 

h2{
  line-height: 0px;
}

.designUpload {
  margin-bottom: 16px;
  
}




.tableDesign {
    border-radius: 8px;
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    background-color: #f5f7fa;
    width: 100%;
}

.tableColumn {
  width: 150px;
  justify-content: left;
  text-align: left;
  padding: 10px;

}

.tableColContent {
  width: 150px;
  justify-content: left;
  text-align: left;
  padding: 10px;
}

.deleteCol {
  width: 150px;
  justify-content: center;
  text-align: center;
  padding: 10px;
}


/* ######## DELETE BUTTON ############# */ 

.deleteButton { 
  background-color: rgb(170, 62, 62,0);
  color: rgb(170, 62, 62); 
  width: 60%;
  height: 36px;
  font-size: 16px;
  border: 0;
  border-radius: 8px;
  border: 1px solid rgb(170, 62, 62);
}

.deleteButton:hover { 
  background-color: rgb(170, 62, 62);
  color: white; 
  width: 60%;
  height: 36px;
  font-size: 16px;
  border: 0;
  border-radius: 8px;
}

.deleteButton:active { 
  background-color: rgb(170, 62, 62);
  color: white; 
  width: 60%;
  height: 36px;
  font-size: 16px;
  border: 0;
  border-radius: 8px;
  font-weight: bold;
}


/* ######## LEFT NAV BAR ############# */ 

.leftNav{ 
  background-color: #4ca9eb;
  color: white;
  height: 100%;

}

.profileImg {
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
  border-radius: 50%;
  object-fit: cover;      /* Ensure the image covers the area without stretching */

}

.navBarRows{
  display: grid;
  flex-direction: row;
  padding: 16px;
  gap: 8px;

}

.navBarButtons{
  height: 36px; 
  background-color: #4ca9eb;
  border: 0px;
  font-size: 16px;
  text-align: left;
  color: #ffffff95;
}

.navBarButtons:hover{
  border: 1px solid white;
  border-radius: 8px;
  color: white;
}

.navBarButtons:active{
  border: 1px solid white;
  background-color: white;
  border-radius: 8px;
  color: #4ca9eb;
}


/* ######## DESIGN NAV BAR ############# */ 

.breadButtons { 
  background-color: white;
  border: 0px;
  margin-right: 16px;
  height: 36px;  
  font-size: 14px
}

.breadButtons:hover{
  border-bottom: 1px solid black;
}

.breadButtons:active{
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* POPUP */ 
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 1000;

}

.popup-content {
    position: fixed; /* Use fixed positioning to center relative to the viewport */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Shift back by half its width and height */
    background-color: white;
    display: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    border-radius: 16px;
    z-index: 1001; /* Ensure it's above the overlay */
    padding: 32px;
}


.popUpImgDiv{
    
  width: 100%;
  background: grey;
  object-fit: cover;
  border-radius: 8px;
  border: 0px

}